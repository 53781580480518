<template>
  <div id="careerContainer">
    <h3>Career</h3>
    <p>If you are driven by ambition and desire to be associated with market leading brands within an organization undergoing rapid regional expansion and having displayed a strong track record of providing its employees with a superior foundation for building long-term professional careers, then we invite you to apply and make a difference in continuing our vision of being the leading sanitary fittings provider in the Middle East.</p>
    <p>Currently we have no openings.</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#careerContainer {
  margin-top: $headerHeight;
  padding: 2rem;
  color: $darkBlue;
  height:100vh;
}
</style>

